import { Button, Col, Dropdown, Input, Modal, Row, message } from "antd";
import React, { useEffect, useRef, useState } from "react";
import Heading from "../../components/SectionHeading/Heading";
import { General_color } from "../../constants/GeneralConstants";
import { BiChevronRight, BiRightArrow, BiRightIndent } from "react-icons/bi";
import { MdArrowRightAlt } from "react-icons/md";
import { RiArrowRightLine } from "react-icons/ri";
import { apiEndPoints } from "../../constants/ApiEndPoints";
import Axios from "axios";
import SpinnerLoader from "../../components/SpinnerLoader/SpinnerLoader";
import WarningIcons from "../../components/WarningIcons/WarningIcons";
import {
  setEdgeAppDetails,
  setPageNotifications,
} from "../../reduxStore/actions";
import { useDispatch, useSelector } from "react-redux";
import { CreateGuid, generateTime } from "../../constants/commonFunction";
import NoDataComponent from "../../components/NoDataComponent/NoDataComponent";
import ReactMarkdown from "react-markdown";
import { IoCloseCircleSharp } from "react-icons/io5";
import { useHistory } from "react-router-dom";
import { FaChevronDown } from "react-icons/fa";

function SoftwareVersionsComponents() {
  const history = useHistory();
  const queryParam = new URLSearchParams(window.location.search);
  const edgeId = queryParam.get("edgeId");
  // let edgeId = '9ab48003-2095-a18a-3262-ed0c201ed420';
  useEffect(() => {
    if (!edgeId) {
      history.push("/");
    }
  }, [edgeId, history]);
  const confirmationRef = useRef(null);
  const dispatch = useDispatch();
  const [showNewUpdate, setShowNewUpdate] = useState(true);
  const [shoeNewUpdate_platform, setShowNewUpdate_platform] = useState(true);
  const [osDetails, setOsDetails] = useState(null);
  const [osMore, setOSMore] = useState(null);
  const [isApiLoaded, setApiLoaded] = useState(false);
  const [updateBtnLoading, setUpdateBtnLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [platform, setPlatform] = useState([]);
  const [apiLoaded_platform, setApiLoaded_platform] = useState(false);
  const [platformReleaseNotes, setPlatformReleaseNotes] = useState([]);
  const [runtimeLoading, setRuntimeLoading] = useState(false);
  const [showOverlayonRuntimeBtn, setShowOverlayonRuntimeBtn] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [selectedKey, setSelectedKey] = useState(1);
  const [confirmModalVisible, setconfirmModalVisible] = useState(false);
  const [isConfirmation, setIsConfirmation] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");
  const [completeStatusMessage, setCompleteStatusMessage] = useState({
    icon: "",
    msg: ``,
  });
  // const [dots, setDots] = useState("");
  const sampleStatus = {
    None: "none",
    DownloadCompleted: "Download Completed",
    UpdateCompleted: "Update Completed",
    DownLoadProgress: "Download in Progress",
    UpdateProgress: "Update in Progress",
    DownloadFailed: "Download Failed",
    UpdateFailed: "Update Failed",
    OsAlreadyInstalled: "OS is already installed",
  };

  const sampleAlreadyStatus = {
    alreadyDownloadOs: "OS is already downloaded.",
    alreadyInstalled: "OS is already installed.",
  };
  const [modalData, setModalData] = useState({
    title: "EdgeniusOS updates",
  });
  const [disabledOption, setDisabledOption] = useState([
    false,
    false,
    false,
    false,
  ]);

  useEffect(() => {
    let fetchEdgeDetails = apiEndPoints.FETCH_SINGLE_EDGE_DETAILS.replace(
      "*edgeId*",
      edgeId
    );

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: fetchEdgeDetails,
    };

    Axios.request(config)
      .then((response) => {
        localStorage.setItem(
          "edgeName",
          response?.data?.edgeDetails?.[0]?.name
        );

        dispatch(
          setEdgeAppDetails({
            payload: {
              data: response.data,
              isLoading: false,
            },
          })
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  let thismsg;

  useEffect(() => {
    async function fetchData() {
      const response = await fetchOsStatus("Download");

      console.log("msgmsg", response);
    }

    fetchData();
  }, []);

  // useEffect(() => {
  //   let dotCount = 0;
  //   const dotInterval = setInterval(() => {
  //     setDots(".".repeat((dotCount % 3) + 1));
  //     dotCount++;
  //   }, 500);

  //   return () => clearInterval(dotInterval);
  // }, []);

  useEffect(() => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: apiEndPoints.FETCH_OS(edgeId),
    };

    Axios.request(config)
      .then((response) => {
        setOsDetails(response.data);
        setOSMore([
          {
            releaseDate: response?.data?.releaseDate,
            releaseKnownIssues: response?.data?.releaseKnownIssues,
            releaseNotes: response?.data?.releaseNotes,
            version: response?.data?.updatedVersion,
          },
        ]);

        setApiLoaded(true);
      })
      .catch((error) => {
        console.log("This is err", error);
        setIsError(true);
        setApiLoaded(true);
      });

    let Platform_config = {
      method: "get",
      maxBodyLength: Infinity,
      url: apiEndPoints.FETCH_RUNTIME(edgeId),
    };

    Axios.request(Platform_config)
      .then((response) => {
        // setOsDetails(response.data);
        setApiLoaded_platform(true);
        setPlatform(response.data);
      })
      .catch((error) => {
        console.log("This is err", error);
        // setIsError(true)
        setApiLoaded_platform(true);
      });

    let Release_config = {
      method: "get",
      maxBodyLength: Infinity,
      url: apiEndPoints.FETCH_RELEASE_INFO_RUNTIME(edgeId),
    };

    Axios.request(Release_config)
      .then((response) => {
        setPlatformReleaseNotes(response.data);
        // setApiLoaded(true);
        console.log("platformReleaseNotes", response.data);
      })
      .catch((error) => {
        console.log("This is err", error);
        // setIsError(true)
        // setApiLoaded(true);
      });
  }, []);

  const AppReleaseNote = (props) => {
    if (props.releaseNote) {
      let formattedDate =
        props.releaseNote.releaseDate == "%%ReleaseDate%%"
          ? "%%ReleaseDate%%"
          : atob(props.releaseNote.releaseDate || "");
      formattedDate = formattedDate.replace(/T/g, " ");
      formattedDate = formattedDate.replace(/[+].*/g, "");
      return (
        <>
          <div className="release-notes-header-wrap">
            {/* <div
              className="release-note-version"
              style={{ marginTop: "10px", fontFamily: "ABBVoice-bold" }}
            >{`${props.releaseNote?.name}`}</div> */}
            <div className="release-note-version">{`VERSION  ${props.releaseNote?.version}`}</div>
            <div className="release-note-time-stamp">{`${formattedDate}`}</div>
          </div>
          <div className="releasenote-text">
            <ReactMarkdown
              children={
                props.releaseNote?.releaseNotes == "%%ReleaseNotes%%"
                  ? "%%ReleaseNotes%%"
                  : atob(props.releaseNote?.releaseNotes || "")
              }
            />
          </div>
          <div className="releasenote-known-issues">
            {/* <p>Known Issues</p> */}
            <ReactMarkdown
              children={
                props.releaseNote?.releaseKnownIssues ==
                "%%ReleaseKnownIssues%%"
                  ? "%%ReleaseKnownIssues%%"
                  : atob(props.releaseNote?.releaseKnownIssues || "")
              }
            />
          </div>
        </>
      );
    } else {
      return <></>;
    }
  };

  const renderHeading = (txt, showVal) => {
    return (
      <div
        className="Heading_container"
        style={{
          display: "flex",
          alignItems: "center",
          columnGap: "10px",
          width: "100%",
          borderBottom: "1px solid #f0f0f0",
        }}
      >
        <Heading text={txt} />
        {!showVal && <SpinnerLoader />}
      </div>
    );
  };

  const renderButton = (txt, type, handleClick, loading) => {
    if (txt == "Close") {
      return (
        <IoCloseCircleSharp
          fontSize="20px"
          style={{ cursor: "pointer" }}
          onClick={handleClick}
        />
      );
    } else {
      return (
        <Button
          className="text_1"
          style={{
            background: type ? "#1f1f1f" : General_color.disable,
            color: type ? "#fff" : "#000",
            display: "flex",
            alignItems: "center",
            borderRadius: "31px",
            padding: "5px 15px",
            // boxShadow: " 0px 4px 6px -1px #d2d2d2",
            cursor: type ? "pointer" : "not-allowed",
            fontWeight: "500",
            border: "0px",
            //   color: "white",
            // marginLeft: "auto",
            fontFamily: "ABBVoice",
          }}
          loading={loading}
          onClick={handleClick}
          disabled={!type}
        >
          {txt}
        </Button>
      );
    }
  };

  let oldNotifications = useSelector((state) => state.setPageNotifications);

  const AddEdgeSettingsNotification = async (notiText, notiId, data) => {
    let previosData = [];
    if (oldNotifications.newData.length > 0) {
      previosData = [...oldNotifications.newData];
    }

    previosData.push({
      notiId: `${notiId}`,
      text: `${notiText}`,
      time: data.time,
      data,
    });

    dispatch(
      setPageNotifications({
        payload: {
          isNew: true,
          data: oldNotifications.data,
          newData: previosData,
          // ...oldNotifications.newData,
        },
      })
    );
  };

  let notiMsg;

  const fetchOsStatus = async (action) => {

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: apiEndPoints.FETCH_OS_UPDATE_STATUS(edgeId),
    };

    console.log(config);
    let statusFromApi = "";
    return Axios.request(config)
      .then((response) => {
        statusFromApi = response.data.status.status;
        // let statusFromApi = sampleStatus.DownloadFailed;
        // console.log("ye aya hai", statusFromApi)
        setStatusMessage(
          statusFromApi === sampleStatus.None ? "Loading" : statusFromApi
        );

        setTimeout(async () => {
          if (sampleStatus.None == statusFromApi) {
            setStatusMessage("");
          }

          if (
            sampleStatus.DownLoadProgress == statusFromApi ||
            sampleStatus.UpdateProgress == statusFromApi
          ) {
            const checkmsgagain = await fetchOsStatus(action);
            if (checkmsgagain == sampleStatus.None) {
              setCompleteStatusMessage({
                icon: "good",
                msg: `${
                  action == "Download"
                    ? "Download Successfully"
                    : action=="DownloadAndInstall"?"Download and Installed Successfully":"Installed Successfuly"
                }`,
              });

              AddEdgeSettingsNotification(
                `${
                  action == "Download"
                  ? "Download Successfully"
                  : action=="DownloadAndInstall"?"Download and Installed Successfully":"Installed Successfuly"
                }`,
                `osUpdate_${CreateGuid()}`,
                {
                  edgeId: edgeId,
                  isEdge: "osUpdate",
                  modalKey: `osUpdate_${CreateGuid()}`,
                  edgeName: localStorage.getItem("edgeName"),
                  successMessage: `${
                    action == "Download"
                    ? "Download Successfully"
                    : action=="DownloadAndInstall"?"Download and Installed Successfully":"Installed Successfuly"
                  }`,
                  appName: "Operating System",
                  time: generateTime(),
                  isComplete: true,
                  limit: 3,
                  currStateValue: 0,
                  icon: "good",
                }
              );
              setTimeout(() => {
                window.location.reload();
              }, 5000);
            }
          }

          if (
            sampleStatus.DownloadCompleted == statusFromApi ||
            sampleStatus.UpdateCompleted == statusFromApi ||
            sampleStatus.DownloadFailed == statusFromApi ||
            sampleStatus.UpdateFailed == statusFromApi
          ) {
            setTimeout(() => {
              setStatusMessage("");
            }, 2000);
            if (
              statusMessage == sampleStatus.DownloadCompleted ||
              statusMessage == sampleStatus.UpdateCompleted
            ) {
              setCompleteStatusMessage({
                icon: "good",
                msg:`${
                    action == "Download"
                    ? "Download Successfully"
                    : action=="DownloadAndInstall"?"Download and Installed Successfully":"Installed Successfuly"
                  }`,
              });
            } else if (
              statusMessage == sampleStatus.DownloadFailed ||
              statusMessage == sampleStatus.UpdateFailed
            ) {
              setCompleteStatusMessage({
                icon: "failed",
                msg: `${
                    action == "Download"
                    ? "Download Failed"
                    : action=="DownloadAndInstall"?"Download and Installed Failed":"Installed Failed"
                  }`,
              });
            } else if (
              statusFromApi == sampleStatus.UpdateCompleted ||
              statusFromApi == sampleStatus.OsAlreadyInstalled
            ) {
              setTimeout(() => {
                window.location.reload();
              }, 5000);
            }
          }
        }, 10000);
        return statusFromApi;
      })
      .catch((error) => {
        console.log(error);
        setStatusMessage("Error Occured");
       
      })
      .finally(() => {
        // setStatusMessage('') //to enable disable btn
      });
  };
  const handleUpdateOS = (action) => {
    setUpdateBtnLoading(true);

    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: apiEndPoints.DOWNLOAD_INSTALL_OS(edgeId),
      data: {
        version: osDetails?.updatedVersion,
        action: action,
      },
    };
    console.log("This is config", config);

    Axios.request(config)
      .then((response) => {
        if (
          response.data == sampleAlreadyStatus.alreadyDownloadOs ||
          response.data === sampleAlreadyStatus.alreadyInstalled
        ) {
          setCompleteStatusMessage({
            icon: "INFO",
            msg: response.data,
          });

          // notiMsg = "Runtime update failed, please try again";
          AddEdgeSettingsNotification(
            `${response.data}`,
            `osUpdate_${CreateGuid()}`,
            {
              edgeId: edgeId,
              isEdge: "osUpdate",
              modalKey: `osUpdate_${CreateGuid()}`,
              edgeName: localStorage.getItem("edgeName"),
              successMessage: `${response.data}`,
              appName: "Operating System",
              time: generateTime(),
              isComplete: true,
              limit: 3,
              currStateValue: 0,
              icon: "INFO",
            }
          );
        }
        if (response.data == "") {
          fetchOsStatus(action);
        } else {
          setStatusMessage(response.data);

          setTimeout(() => {
            setStatusMessage("");
          }, 2000);
        }
      })
      .catch((err) => {
        console.log("error occure", err.response.data);
        setStatusMessage(err.response.data);
        setTimeout(() => {
          setStatusMessage("");
        }, 2000);
      })
      .finally(() => {
        setUpdateBtnLoading(false);
      });
  };

  const handleUpdatePlatform = () => {
    setRuntimeLoading(true);
    
    const guid = CreateGuid();
    localStorage.setItem("correlationId", guid);

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: apiEndPoints.UPDATE_RUNTIME(edgeId, guid),
    };

    Axios.request(config)
      .then((response) => {
        setShowOverlayonRuntimeBtn(true)
        console.log(response.data);
        notiMsg = "Runtime updated successfully";
        AddEdgeSettingsNotification(
          `${notiMsg}`,
          `runtimeUpdate_${CreateGuid()}`,
          {
            edgeId: edgeId,
            isEdge: "runtimeUpdate",
            modalKey: `runtimeUpdate_${CreateGuid()}`,
            edgeName: localStorage.getItem("edgeName"),
            successMessage: `${notiMsg}`,
            appName: "Edgenius Runtime",
            time: generateTime(),
            isComplete: false,
            limit: 5,
            currStateValue: 0,
            icon: "good",
          }
        );
      })
      .catch((error) => {
        setShowOverlayonRuntimeBtn(false)
        console.log(error);
        notiMsg = "Runtime update failed, please try again";
        AddEdgeSettingsNotification(
          `${notiMsg}`,
          `runtimeUpdate_${CreateGuid()}`,
          {
            edgeId: edgeId,
            isEdge: "runtimeUpdate",
            modalKey: `runtimeUpdate_${CreateGuid()}`,
            edgeName: localStorage.getItem("edgeName"),
            successMessage: `${notiMsg}`,
            appName: "Edgenius Runtime",
            time: generateTime(),
            isComplete: true,
            limit: 3,
            currStateValue: 0,
            icon: "failed",
          }
        );

        message.error(notiMsg);
      })
      .finally(() => {
        setRuntimeLoading(false);
      });
  };

  const handleButtonClick = (e) => {
    setconfirmModalVisible(true);
  };

  const handleDownload = () => {
    // if(selectedKey=='1'){
    //   handleUpdateOS('download')
    // }else  if(selectedKey=='2'){
    //   handleUpdateOS('DownloadAndInstall')
    // }
    handleUpdateOS(items.filter((i) => i.key == `${selectedKey}`)[0].action);
  };
  const items = [
    {
      label: "Copy To Edge",
      key: "1",
      action: "Download",
      disabled: disabledOption[1],
    },
    {
      label: "Copy And Install",
      key: "2",
      action: "DownloadAndInstall",
      disabled: disabledOption[2],
    },
    {
      label: "Install",
      key: "3",
      action: "Install",
      // icon: <UserOutlined />,
      // danger: true,
      disabled: disabledOption[3],
    },
  ];

  const handleMenuClick = (e) => {
    console.log("click", e.key);
    setSelectedKey(e.key);
  };

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  const renderOperatingSys = () => {
    return (
      <>
        {renderHeading("Operating System", isApiLoaded)}
        {!isError ? (
          <>
            {isApiLoaded && (
              <div
                style={{
                  fontFamily: "ABBVoice",
                  display: "flex",
                  // flexWrap: "wrap",
                  gap: "10px",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingTop: "25px",
                }}
              >
                <div
                  style={{
                    width: "32%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    lineHeight: "1.5",
                    justifyContent: "center",
                    borderRight: "1px solid #f0f0f0",
                    height: "20vh",
                    padding: "10px",
                  }}
                >
                  <span style={{ fontSize: "18px" }}>{"Edgenius OS"}</span>
                  <span style={{ fontSize: "15px" }}>
                    {osDetails?.version ? osDetails?.version : "--"}
                  </span>
                </div>

                <div
                  style={{
                    width: osDetails?.isUpdateAvailable ? "32%" : "64%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "10px",
                    justifyContent: "center",
                    borderRight: osDetails?.isUpdateAvailable
                      ? "1px solid #f0f0f0"
                      : "0px solid #f0f0f0",
                    height: "20vh",
                  }}
                >
                  <span
                    style={{ fontSize: "15px", display: "flex", gap: "20px" }}
                  >
                    {/* {false ? ( */}
                    {osDetails?.isUpdateAvailable ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <span>
                          Version {osDetails?.updatedVersion} Available{" "}
                          {/* <u
                            style={{
                              fontWeight: "100",
                              cursor: "pointer",
                              marginLeft: "10px",
                            }}
                            onClick={() =>
                              showNewUpdate
                                ? setShowNewUpdate(false)
                                : setShowNewUpdate(true)
                            }
                          >
                            {showNewUpdate ? "Less" : "More"}
                          </u> */}
                        </span>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                          }}
                        >
                          {/* {renderButton(
                            "Update",
                            localStorage.getItem("tenant") == "chiron",
                            () => {
                              handleUpdateOS();
                            },
                            updateBtnLoading
                          )} */}

                          {localStorage.getItem("tenant") == "chiron" && (
                            <>
                              <div className="dropBtn">
                                {statusMessage !== "" && (
                                  <div className="OverlayDiv"></div>
                                )}

                                <Dropdown.Button
                                  menu={menuProps}
                                  onClick={handleButtonClick}
                                  icon={
                                    !updateBtnLoading ? (
                                      <FaChevronDown />
                                    ) : (
                                      <SpinnerLoader
                                        style={{ color: "#fff" }}
                                      />
                                    )
                                  }
                                >
                                  {items[selectedKey - 1].label}
                                </Dropdown.Button>
                              </div>
                            </>
                          )}
                        </div>
                        {statusMessage !== "" && (
                          <span
                            style={{
                              fontSize: "12px",
                              // position: "absolute",
                              // bottom: "20px",
                            }}
                          >
                            {`${statusMessage}`} <SpinnerLoader />
                          </span>
                        )}
                        {completeStatusMessage.msg !== "" && (
                          <span
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              gap: 5,
                            }}
                          >
                            <WarningIcons type={completeStatusMessage.icon} />
                            {completeStatusMessage.msg}
                          </span>
                        )}
                      </div>
                    ) : (
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <WarningIcons type="good" />
                        You're up to date
                      </span>
                    )}
                  </span>
                </div>
                {osDetails?.isUpdateAvailable && (
                  <div
                    style={{
                      width: "32%",
                      display: "flex",
                      flexDirection: "column",
                      gap: "5px",
                      overflow: "auto",
                      maxHeight: "35vh",
                      height: "45vh",
                      // alignItems: "center",
                      padding: "0px 10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <b
                        style={{
                          fontSize: "15px",
                          textAlign: "center",
                          marginBottom: "10px",
                          display: "block",
                        }}
                      >
                        What's New
                      </b>
                      {/* <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        {renderButton("Close", false, () =>
                          setShowNewUpdate_platform(false)
                        )}
                      </div> */}
                    </div>
                    {osMore.map((releasenote) => (
                      <AppReleaseNote releaseNote={releasenote} />
                    ))}
                  </div>
                )}
                {
                  //osDetails?.isUpdateAvailable ? (
                  // {false ? (
                  // <div
                  //   style={{
                  //     width: "32%",
                  //     display: "flex",
                  //     flexDirection: "column",
                  //     gap: "5px",
                  //     overflow: "auto",
                  //     maxHeight: "35vh",
                  //     height: "45vh",
                  //     // alignItems: "center",
                  //     padding: "0px 10px",
                  //   }}
                  // >
                  //   {showNewUpdate && (
                  //     <>
                  //       <div
                  //         style={{
                  //           display: "flex",
                  //           justifyContent: "space-between",
                  //           alignItems: "center",
                  //         }}
                  //       >
                  //         <div
                  //           style={{ display: "flex", flexDirection: "column" }}
                  //         >
                  //           <b style={{ fontSize: "15px" }}>What's New</b>
                  //         </div>
                  //         {/* <div
                  //           style={{ display: "flex", justifyContent: "end" }}
                  //         >
                  //           {renderButton("Close", false, () =>
                  //             setShowNewUpdate(false)
                  //           )}
                  //         </div> */}
                  //       </div>
                  //       {osMore.map((releasenote) => (
                  //         <AppReleaseNote releaseNote={releasenote} />
                  //       ))}
                  //     </>
                  //   )}
                  // </div>
                  //) : (
                  // <div
                  //   style={{
                  //     width: "32%",
                  //     display: "flex",
                  //     flexDirection: "column",
                  //     alignItems: "center",
                  //     justifyContent: "center",
                  //     gap: "5px",
                  //     overflow: "auto",
                  //     maxHeight: "35vh",
                  //     height: "45vh",
                  //     color: General_color.disable,
                  //     padding: "0px 10px",
                  //   }}
                  // >
                  //   <span style={{ fontSize: "15px" }}>View change logs</span>
                  // </div>
                  //)
                }
              </div>
            )}
          </>
        ) : (
          <NoDataComponent message={"No data available for OS"} />
        )}
      </>
    );
  };

  const renderEdgeniusRuntime = () => {
    return (
      <>
        {renderHeading("Edgenius Runtime", apiLoaded_platform)}
        {platform?.platformConfiguration?.length > 0 && (
          <div
            style={{
              fontFamily: "ABBVoice",
              display: "flex",
              // flexWrap: "wrap",
              gap: "10px",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: "10px",
            }}
          >
            <div
              style={{
                width: "32%",
                display: "flex",
                flexDirection: "column",
                // alignItems: "center",
                gap: "15px",
                // justifyContent: "center",
                borderRight: "1px solid #f0f0f0",
                height: "20vh",
                overflow: "auto",
                padding: "10px",
              }}
            >
              <span style={{ fontSize: "15px" }}>
                Edgenius Core runtime includes IAM services, gateway services,
                and application hosting, forming the foundational layer of the
                Edgenius Application Platform. It ensures secure user
                authentication, seamless connectivity, and efficient application
                management for scalable and reliable edge computing solutions.
              </span>
              {platform.platformConfiguration.length > 0 && (
                <>
                  <span
                    style={{ fontSize: "15px", cursor: "pointer" }}
                    className="Hyper_link"
                    onClick={() => setShowDetails(!showDetails)}
                  >
                    {!showDetails ? "Show" : "Hide"} Details
                  </span>

                  {showDetails &&
                    platform.platformConfiguration.map((plat, i) => (
                      <span
                        key={`palt_${i}`}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          gap: "5px",
                          lineHeight: "0.5",
                        }}
                      >
                        <span style={{ fontSize: "12px" }}>{plat.name}</span>
                        <span
                          style={{ fontSize: "12px" }}
                        >{` v${plat.version}`}</span>
                      </span>
                    ))}
                </>
              )}
            </div>
            <div
              style={{
                width: platform.isUpdateAvailable ? "32%" : "64%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "10px",
                justifyContent: "center",
                borderRight: platform.isUpdateAvailable
                  ? "1px solid #f0f0f0"
                  : "0px solid #f0f0f0",
                height: "20vh",
                fontSize: "15px",
              }}
            >
              {/* {false ? (  */}
              {platform.isUpdateAvailable ? (
                <>
                  <span>
                    Latest Runtime Available{" "}
                    {/* <u
                      style={{
                        fontWeight: "100",
                        cursor: "pointer",
                        marginLeft: "10px",
                      }}
                      onClick={() =>
                        shoeNewUpdate_platform
                          ? setShowNewUpdate_platform(false)
                          : setShowNewUpdate_platform(true)
                      }
                    >
                      {shoeNewUpdate_platform ? "Less" : "More"}
                    </u> */}
                  </span>
                  <div style={{ position: "relative" }}>
                    {renderButton(
                      "Update",
                      true,
                      () => {
                        handleUpdatePlatform();
                      },
                      runtimeLoading
                    )}
                    {showOverlayonRuntimeBtn && (

                      <div
                      className="OverlayDiv"
                      style={{
                        position: "absolute",
                        top: "0",
                        width: "100%",
                      }}
                      ></div>
                    )}
                  </div>
                </>
              ) : (
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <WarningIcons type="good" />
                  You're up to date
                </span>
              )}
            </div>

            {platform.isUpdateAvailable && (
              // {false?(
              <div
                style={{
                  width: "32%",
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                  overflow: "auto",
                  maxHeight: "35vh",
                  height: "45vh",
                  //   paddingBottom:'10px'
                }}
              >
                {shoeNewUpdate_platform && (
                  <div style={{ padding: "0px 10px" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <b
                        style={{
                          fontSize: "15px",
                          textAlign: "center",
                          marginBottom: "10px",
                          display: "block",
                        }}
                      >
                        What's New
                      </b>
                      {/* <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        {renderButton("Close", false, () =>
                          setShowNewUpdate_platform(false)
                        )}
                      </div> */}
                    </div>
                    {platformReleaseNotes.map((releasenote) => (
                      <AppReleaseNote releaseNote={releasenote} />
                    ))}
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </>
    );
  };
  const checkTheConfirmationValue = () => {
    const inputValue = confirmationRef.current.input.value;
    if (inputValue.toLowerCase() == "yes") {
      setIsConfirmation(true);
    } else {
      setIsConfirmation(false);
    }
  };
  return (
    <Row style={{ gridGap: "10px", paddingRight: "20px" }}>
      <Modal
        title={modalData.title}
        open={confirmModalVisible}
        onCancel={() => setconfirmModalVisible(false)}
      >
        <div style={{ padding: "30px 40px" }}>
          {selectedKey == "1" && (
            <div className="updateModalContainer">
              <span>
                Please ensure there is a stable internet connection to download
                large files.
              </span>
              <span>Type Yes to continue or cancel to try later.</span>
            </div>
          )}

          {selectedKey == "2" && (
            <div className="updateModalContainer">
              <span>
                Please ensure there is a stable internet connection. System will
                automatically <b>Restart</b> during update process.
              </span>
              <span>
                Alternatively, select the "Copy To Edge" option to download the
                updates now and install then manually at the later time.
              </span>
              <span>Type Yes to continue.</span>
            </div>
          )}

          {selectedKey == "3" && (
            <div className="updateModalContainer">
              <span>
                Please ensure there is a stable internet connection. System will
                automatically <b>Restart</b> during update process.
              </span>
              <span>Type Yes to continue or cancel to try later.</span>
            </div>
          )}

          <div
            style={{
              display: "flex",
              gap: "20px",
              marginTop: "30px",
              alignItems: "end",
            }}
          >
            <div>
              <span>
                {selectedKey == 1 && "Copy Updates"}
                {selectedKey == 2 && "Copy And Install"}
                {selectedKey == 3 && "Install Updates"}
              </span>
              <Input
                ref={confirmationRef}
                onChange={checkTheConfirmationValue}
                placeholder="Yes"
              />
            </div>
            <Button
              className="text_1"
              style={{
                background: isConfirmation ? "#1f1f1f" : General_color.disable,
                color: isConfirmation ? "#fff" : "#000",
                display: "flex",
                alignItems: "center",
                borderRadius: "31px",
                padding: "5px 15px",
                // boxShadow: " 0px 4px 6px -1px #d2d2d2",
                cursor: isConfirmation ? "pointer" : "not-allowed",
                fontWeight: "500",
                border: "0px",
                //   color: "white",
                // marginLeft: "auto",
                fontFamily: "ABBVoice",
                width: "150px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              // loading={loading}
              onClick={() => {
                setconfirmModalVisible(false);
                setStatusMessage("Please wait");
                handleDownload();
              }}
              disabled={!isConfirmation}
            >
              Continue
            </Button>
            <Button
              className="text_1"
              style={{
                background: true ? "#1f1f1f" : General_color.disable,
                color: true ? "#fff" : "#000",
                display: "flex",
                alignItems: "center",
                borderRadius: "31px",
                padding: "5px 15px",
                // boxShadow: " 0px 4px 6px -1px #d2d2d2",
                cursor: true ? "pointer" : "not-allowed",
                fontWeight: "500",
                border: "0px",
                //   color: "white",
                // marginLeft: "auto",
                fontFamily: "ABBVoice",
                width: "150px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              // loading={loading}
              onClick={() => setconfirmModalVisible(false)}
              // disabled={!type}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
      <Col
        flex="1 1 525px"
        style={{
          columnGap: "10px",
          display: "grid",
          rowGap: "10px",
        }}
      >
        <Row style={{ gridGap: "10px" }}>
          <Col
            className="gridContainer"
            flex="1"
            style={{
              borderRadius: "10px",
              height: "45vh",
            }}
          >
            <div className="">{renderOperatingSys()}</div>
          </Col>
        </Row>
        <Row style={{ gridGap: "10px" }}>
          <Col
            className="gridContainer"
            flex="1"
            style={{
              borderRadius: "10px",
              height: "45vh",
            }}
          >
            <div className="">{renderEdgeniusRuntime()}</div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default SoftwareVersionsComponents;
