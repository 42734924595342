import { envSettings } from "../EnviornmentSettings/Settings";

export const apiEndPoints = {
  BASE: "",
  FETCH_METRICS: `${envSettings.metricsApiUrl}/metrics/fetchInstantMetrics`,
  FETCH_EVENTS: `${envSettings.metricsApiUrl}/logs/fetchK8sEvents`,
  FETCH_GRAPH: `${envSettings.metricsApiUrl}/metrics/fetchRangeMetrics`,

  // FETCH_ALL_EDGES_APP: `${envSettings.webapiUrl}/Edge/edges?platformType=&includeApps=true&includeConnectionStatus=false&metrics=false`,
  FETCH_ALL_EDGED_APP_METRICS: `${envSettings.webapiUrl}/Edge/edges`,
  FETCH_ALL_EDGE_NAMES: `${envSettings.webapiUrl}/Edge/edge-names`,
  CHECK_EDGE_UPDATE: `${envSettings.webapiUrl}/Edge/edges/*edgeId*/update/availability`,
  UPDATE_EDGE: `${envSettings.webapiUrl}/Edge/edges/*edgeId*/update?correlationId=*correlationId*`,
  FETCH_SINGLE_EDGE_DETAILS: `${envSettings.webapiUrl}/Edge/edges/*edgeId*/info`,
  FETCH_AVAILABLE_APPS: `${envSettings.webapiUrl}/edges/*edgeId*/availableApplications`,
  FETCH_ALL_AVAILABLE_APPS: `${envSettings.webapiUrl}/applications`,
  FETCH_INSTALLED_APPS: `${envSettings.webapiUrl}/edges/*edgeId*/applications`,
  UPDATE_INSTALLED_APP: `${envSettings.webapiUrl}/updateApplicationSettings`,
  DELETE_EDGE: `${envSettings.webapiUrl}/Edge/edges/*edgeId*`,

  FETCH_BASIC_EDGE_SETTINGS: `${envSettings.webapiUrl}/Edge/edges/*edgeId*/settings`,
  // SET_EDGE_CONFIGURATION: `${envSettings.webapiUrl}/edge/*edgeId*/edgeconfig`,
  SET_BASIC_EDGE_SETTINGS: `${envSettings.webapiUrl}/Edge/edges/*edgeId*/settings?correlationId=*correlationId*`,
  SET_ALL_EDGE_SETTINGS: `${envSettings.webapiUrl}/Edge/edges/*edgeId*/settings`,

  // FETCH_APPLICATION_SETTINGS: `${envSettings.webapiUrl}/edges/*edgeId*/applications/*typeId*/capabilities?isApplicationInstalled=*isInstalled*`,
  FETCH_APPLICATION_SETTINGS: `${envSettings.webapiUrl}/edges/*edgeId*/applications/*typeId*/capabilities?includeMethods=true`,
  FETCH_AVAILABLE_APPLICATION_SETTINGS: `${envSettings.webapiUrl}/applications/*typeId*/capabilities?includeMethods=false`,
  INVOKE_METHOD: (objectId, methodName) =>
    `${envSettings.webapiUrl}/modules/${objectId}/methods/${methodName}`,
  FETCH_APPLICATION_STATUS: `${envSettings.webapiUrl}/edge/*edgeId*/applications/*typeId*/status?action=*operationType*`,

  FETCH_EDGE_SETTING_STATUS: `${envSettings.webapiUrl}/Edge/edges/*edgeId*/applications/*parentTypeId*/settings/status?sectionName=*sectionName*`,

  INSTALL_APPLICATION: `${envSettings.webapiUrl}/installApplication`,
  FETCH_ROUTER_MODULES: `${envSettings.webapiUrl}/Edge/edges/*edgeId*/router/models`,

  UPDATE_EDGE_AUTH_SETTINGS: `${envSettings.webapiUrl}/Edge/edgesetup/deployEdge?edgeId=*edgeId*&IsInstallation=*checkstatus*&correlationId=*correlationId*`,

  GET_EDGE_DEPLOY_STATUS: `${envSettings.webapiUrl}/Edge/edges/*edgeId*/deployment/status?correlationId=*correlationId*`,
  FETCH_STATUS_EDGE_DETAILS: `${envSettings.webapiUrl}/Edge/edges/*edgeId*/status`,
  FETCH_STATUS_RESOURCE_DETAILS: `${envSettings.webapiUrl}/Edge/edges/*edgeId*/status?isResource=true`,
  FETCH_SYSTEM: `${envSettings.webapiUrl}/System`,
  ADD_NEW_SYSTEM: `${envSettings.webapiUrl}/System`,
  DELETE_SYSTEM: `${envSettings.webapiUrl}/System/*systemId*`,
  DELETE_APPLICATION: (edgeId, type) =>
    `${envSettings.webapiUrl}/edges/${edgeId}/applications/${type}`,
  UPDATE_APPLICATION: `${envSettings.webapiUrl}/edges/*edgeId*/applications/*type*/AppUpdate`,
  CHECK_UPDATE_APPLICATION: `${envSettings.webapiUrl}/edges/*edgeId*/applications/*typeId*/update/availability`,
  FETCH_AVAILABLE_APPLICATION_DETAILS: `${envSettings.webapiUrl}/applications/*typeId*/Details`,
  FETCH_INSTALLED_APPLICATION_DETAILS: `${envSettings.webapiUrl}/edge/*edgeId*/applications/*typeId*/Details`,
  FETCH_APPLICATION_LICENSE: `${envSettings.webapiUrl}/applications/*typeId*/licenses`,
  FETCH_APPLICATION_RELEASENOTES: `${envSettings.webapiUrl}/applications/*typeId*/releaseNote`,

  FETCH_OS: (edgeId) => `${envSettings.webapiUrl}/Edge/edges/${edgeId}/OS/info`,
  FETCH_RUNTIME: (edgeId) =>
    `${envSettings.webapiUrl}/Edge/edges/${edgeId}/platform/info`,
  DOWNLOAD_OS: (edgeId, newVersion) =>
    `${envSettings.webapiUrl}/Edge/edges/${edgeId}/OS/download/${newVersion}`,
  UPDATE_OS: (edgeId, newVersion) =>
    `${envSettings.webapiUrl}/Edge/edges/${edgeId}/OS/update/${newVersion}`,
  UPDATE_RUNTIME: (edgeId, correlationId) =>
    `${envSettings.webapiUrl}/Edge/edges/${edgeId}/update?correlationId=${correlationId}`,

  FETCH_OS_DOWNLOAD_STATUS: (edgeId) =>
    `${envSettings.webapiUrl}/Edge/edges/${edgeId}/OS/download/status`,
  FETCH_RELEASE_INFO_RUNTIME: (edgeId) =>
    `${envSettings.webapiUrl}/Edge/edges/${edgeId}/platform/releaseInfo`,
  FETCH_RUNTIME_STATUS: (edgeId, correlationId) =>
    `${envSettings.webapiUrl}/Edge/edges/${edgeId}/deployment/status?correlationId=${correlationId}`,

  FETCH_OS_UPDATE_STATUS: (edgeId) =>
    `${envSettings.webapiUrl}/Edge/edges/${edgeId}/OS/update/status`,
  DOWNLOAD_INSTALL_OS: (edgeId) =>
    `${envSettings.webapiUrl}/Edge/edges/${edgeId}/osupgrade`,

  FETCH_APP_DEPENDENCIES: `${envSettings.webapiUrl}/edges/*edgeId*/applications/*typeId*/dependencies`,
  FETCH_APP_UPSTREAM_DEPENDENCIES: `${envSettings.webapiUrl}/edges/*edgeId*/applications/*typeId*/upstreamdependencies`,

  //Resource Limiting Endpoints
  FETCH_RESOURCE_LIMIT_GRAPHS: `${envSettings.webapiUrl}/edge/edges/metricsData`,
  UPDATE_APP_RESOURCES: `${envSettings.webapiUrl}/updateApplicationResources`,
  UPDATE_PLATFORM_RESOURCES: `${envSettings.webapiUrl}/edge/UpdatePlatformResources`,
  API_VERSION: `${envSettings.webapiUrl}/Version`,

  FETCH_OS_DONLOAD_URL: `${envSettings.webapiUrl}/edgeniusos`,

  FETCH_AUDIT_LOGS: `${envSettings.auditLogUrl}/AuditLog/getauditlogs`,

  //Integrated Packages
  extendedCustomAppList: `${envSettings.webapiUrl}/v1/edgenius/mode/disconnected/extendedApplications`,
  edgeniusSoftwareList: `${envSettings.webapiUrl}/v1/edgenius/mode/disconnected/software`,
  edgeSoftwareGenerateLink: `${envSettings.webapiUrl}/v1/edgenius/mode/disconnected/software/downloadLink`,
  extendedCustomAppGenerateLink: `${envSettings.webapiUrl}/v1/edgenius/mode/disconnected/extendedApplications/downloadLink`,

  Fetch_technical_specs: `${envSettings.webapiUrl}/edge/technicalspecifications`,
  Fetch_releaseNote: `${envSettings.webapiUrl}/edge/releaseNote`,
  Fetch_licenses: `${envSettings.webapiUrl}/edge/licenses`,
};
